import React from "react"
import Gallery from "@browniebroke/gatsby-image-gallery"
import "@browniebroke/gatsby-image-gallery/dist/style.css"
import "./PhotoGallery.css"

function PhotoGallery(props) {
  return (
    <div className="photo-gallery">
      <Gallery images={props.images} {...props} />
    </div>
  )
}
export default PhotoGallery
